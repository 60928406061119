import { useCallback, useEffect, useRef, useState } from 'react';

import { useInclassLogger, useMeeting, useSocketSubscribe } from 'hooks';
import { useAppSelector } from 'hooks/store';
import { selectHtmlLink, selectHtmlSlide, selectHtmlStep } from 'store/slice/content.slice';
import { selectMyCurrentState } from 'store/slice/inClassConfig.slice';
import { StateKeys } from 'types';
import './style.css';

const Content = () => {
  // States
  const [htmlLink, setHtmlLink] = useState<string>('');

  // Ref
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // Redux selectors
  const getHtmlLink = useAppSelector(selectHtmlLink);
  const getHtmlSlide = useAppSelector(selectHtmlSlide);
  const getHtmlStep = useAppSelector(selectHtmlStep);
  const myCurrentState = useAppSelector(selectMyCurrentState) || 'teach';

  // Hooks
  const { studentName, classId, studentId } = useMeeting();
  const { datadogLog } = useInclassLogger();

  // Helper function to send 'JumpToAnim' postMessage
  const sendJumpToAnimMessage = useCallback(
    (step: number, slide: number) => {
      if (iframeRef.current) {
        iframeRef.current.contentWindow?.postMessage(
          {
            type: 'uprio_custom',
            fn: 'JumpToAnim',
            params: [step, slide, true],
          },
          '*',
        );
      }
    },
    [iframeRef],
  );

  // Effect to update htmlLink when state changes
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setHtmlLink(getHtmlLink);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [getHtmlLink, myCurrentState]);

  // Send JumpToAnim message when slide or step changes
  useEffect(() => {
    sendJumpToAnimMessage(getHtmlStep, getHtmlSlide);
  }, [getHtmlSlide, getHtmlStep, sendJumpToAnimMessage]);

  // Socket handler for htmlSlidesEveToClient event
  const htmlSlidesHandler = useCallback(
    ({
      htmlLink,
      currentState,
      slide,
      step,
      studentID,
    }: {
      htmlLink: {
        teach: string;
        coach_basic: string;
        coach_intermediate: string;
        coach_advanced: string;
      };
      slide: number;
      step: number;
      currentState: StateKeys;
      studentID: number;
    }) => {
      if (studentID && studentID !== studentId) return;
      if (currentState === myCurrentState) {
        if (step === 0) {
          sendJumpToAnimMessage(step, slide);
        }
        const contentLink = htmlLink?.[myCurrentState];
        setHtmlLink(contentLink);
        datadogLog(`Changing content for ${studentName}`);
      }
    },
    [studentId, myCurrentState, sendJumpToAnimMessage, datadogLog, studentName],
  );

  useSocketSubscribe<{
    htmlLink: {
      teach: string;
      coach_basic: string;
      coach_intermediate: string;
      coach_advanced: string;
    };
    slide: number;
    step: number;
    currentState: StateKeys;
    studentID: number;
  }>('htmlSlidesEveToClient', htmlSlidesHandler);

  // Socket handler for tutorJoined event
  const tutorJoinedHandler = useCallback(
    ({
      slide,
      step,
    }: {
      slide: {
        coach_basic: number;
        coach_intermediate: number;
        coach_advanced: number;
        teach: number;
      };
      step: {
        coach_basic: number;
        coach_intermediate: number;
        coach_advanced: number;
        teach: number;
      };
    }) => {
      setTimeout(() => {
        sendJumpToAnimMessage(
          step?.[myCurrentState as StateKeys],
          slide?.[myCurrentState as StateKeys],
        );
      }, 500);
    },
    [myCurrentState, sendJumpToAnimMessage],
  );

  useSocketSubscribe<{
    slide: {
      coach_basic: number;
      coach_intermediate: number;
      coach_advanced: number;
      teach: number;
    };
    step: {
      coach_basic: number;
      coach_intermediate: number;
      coach_advanced: number;
      teach: number;
    };
  }>('tutorJoinedToClient', tutorJoinedHandler);

  // Iframe load event handler
  const handleIframeLoad = useCallback(() => {
    setTimeout(() => {
      sendJumpToAnimMessage(getHtmlStep, getHtmlSlide);
    }, 500);
  }, [getHtmlSlide, getHtmlStep, sendJumpToAnimMessage]);

  // Construct iframe link
  const link =
    htmlLink +
    '?classId=' +
    classId +
    '&userId=' +
    studentId +
    '&userType=student&classPhase=' +
    myCurrentState +
    '&classType=inClass';

  return (
    <div className='relative size-full'>
      <div className='absolute top-0 left-0 p-3 subHeading'>
        <span className='text-lg font-semibold text-white'>Notes</span>
      </div>
      <div className='h-full '>
        {htmlLink ? (
          <iframe
            src={link}
            ref={iframeRef}
            title="Tutor's slide"
            allowFullScreen={true}
            className='size-full'
            onLoad={handleIframeLoad}
            style={{ pointerEvents: 'none' }}
          ></iframe>
        ) : (
          <div className='flex items-center justify-center text-white size-full'>
            Please inform the tutor that you are unable to see any content
          </div>
        )}
      </div>
    </div>
  );
};

export default Content;
