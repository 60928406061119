interface ClassNamesParams {
  studentsCount: number;
  tutorsLength: number;
  screenResolution: 'HD' | 'FHD' | 'QHD' | 'SD';
  chatToggle: boolean;
  studentsWithoutSelfLength: number;
  activePluginsLength: number;
  screenShareEnabled: boolean;
}

export const getClassNames = ({
  studentsCount,
  tutorsLength,
  screenResolution,
  chatToggle,
  studentsWithoutSelfLength,
  activePluginsLength,
  screenShareEnabled,
}: ClassNamesParams) => {
  let participantsWidth = '';
  let mainTutor = '';
  let mainStudents = '';
  let secondaryStudent = '';
  let secondaryTutor = '';
  let chatClass = '';

  // Handling participantsWidth logic
  if (studentsCount === 3) {
    participantsWidth = 'w-[55vw]';
  } else if (studentsCount === 4) {
    participantsWidth = 'w-[60vw]';
  } else if (tutorsLength < 2 && studentsCount > 4) {
    participantsWidth = 'w-[75vw]';
  } else if (tutorsLength > 2 && studentsCount > 2) {
    participantsWidth = 'w-[90vw]';
  }

  // Handling mainTutor logic
  if (tutorsLength === 1) {
    mainTutor += ' w-[23vw] h-[70vh]';
  } else if (tutorsLength === 2) {
    mainTutor += ' w-[23vw] h-[33vh]';
  } else if (tutorsLength > 2) {
    mainTutor += ' w-[12vw] h-[33vh]';
  }

  // Handling mainStudents logic
  if (screenResolution === 'HD') {
    if (studentsCount < 3) {
      mainStudents += ' w-[23vw] h-[70vh]';
    } else {
      mainStudents += ' w-[14vw] h-[35vh]';
    }
  } else if (screenResolution === 'FHD') {
    if (studentsCount < 3) {
      mainStudents += ' w-[23vw] h-[70vh]';
    } else {
      mainStudents += ' w-[14vw] h-[35vh]';
    }
  }
  if (studentsCount > 6) {
    mainStudents = ' w-[9.4vw] h-[35vh]';
  }
  if (studentsCount > 10) {
    mainStudents = ' w-[9.4vw] h-[22vh]';
  }
  if (studentsCount > 15) {
    mainStudents = ' w-[9.4vw] h-[17vh]';
  }

  // Handling secondaryStudent logic
  if (studentsWithoutSelfLength === 0) {
    if (chatToggle) {
      secondaryStudent += ' w-[9.7vw] h-[19vh]';
    } else {
      secondaryStudent += ' w-[20vw] h-[41vh]';
    }
  } else {
    if (chatToggle) {
      secondaryStudent += ' w-[9.7vw] h-[19vh]';
    } else {
      secondaryStudent += ' h-[35vh] w-[20vw]';
    }
  }

  if (tutorsLength === 1 || tutorsLength === 0) {
    if (screenResolution === 'HD' || screenResolution === 'SD') {
      if (chatToggle) {
        if (studentsWithoutSelfLength !== 0) {
          secondaryTutor += ' w-[9.7vw] h-[19vh]';
        } else {
          secondaryTutor += ' w-[9.7vw] h-[19vh]';
        }
      } else {
        if (studentsWithoutSelfLength !== 0) {
          secondaryTutor += ' h-[42vh] w-[20vw]';
        } else {
          secondaryTutor += ' h-[50vh] w-[20vw]';
        }
      }
    } else if (screenResolution === 'FHD' || screenResolution === 'QHD') {
      if (chatToggle) {
        secondaryTutor += ' h-[19vh] w-[9.7vw]';
      } else {
        if (studentsWithoutSelfLength !== 0) {
          secondaryTutor += ' h-[42vh] w-[20vw]';
        } else {
          secondaryTutor += ' h-[54vh] w-[20vw]';
        }
      }
    }
  } else if (tutorsLength === 2) {
    secondaryTutor += chatToggle ? ' w-[9.7vw] h-[19vh]' : ' h-[39vh] w-[9.7vw]';
  } else if (tutorsLength > 2) {
    secondaryTutor += chatToggle ? ' w-[9.7vw] h-[19vh]' : ' h-[19vh] w-[9.7vw]';
  }

  chatClass = `w-[20vw] ${
    screenResolution === 'FHD' &&
    studentsWithoutSelfLength === 0 &&
    (activePluginsLength > 0 || screenShareEnabled)
      ? 'h-[65vh]'
      : ''
  } ${
    screenResolution === 'HD' &&
    studentsWithoutSelfLength === 0 &&
    (activePluginsLength > 0 || screenShareEnabled)
      ? 'h-[55vh]'
      : ''
  } ${
    screenResolution === 'FHD' &&
    studentsWithoutSelfLength > 0 &&
    (activePluginsLength > 0 || screenShareEnabled)
      ? 'h-[55vh]'
      : ''
  } ${
    screenResolution === 'HD' &&
    studentsWithoutSelfLength > 0 &&
    (activePluginsLength > 0 || screenShareEnabled)
      ? 'h-[45vh]'
      : ''
  } ${
    screenResolution === 'FHD' && !(activePluginsLength > 0 || screenShareEnabled) ? 'h-[85vh]' : ''
  } ${
    screenResolution === 'HD' && !(activePluginsLength > 0 || screenShareEnabled) ? 'h-[79vh]' : ''
  } flex flex-col no-scrollbar overflow-y-scroll px-4`;

  return {
    participantsWidth,
    mainTutor,
    mainStudents,
    secondaryStudent,
    secondaryTutor,
    chatClass,
  };
};
